import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Caption = makeShortcode("Caption");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The import action transfers data or objects from an external source into a system.`}</p>
    </PageDescription>
    <h4 {...{
      "id": "status"
    }}>{`Status:`}</h4>
    <p><a parentName="p" {...{
        "href": "experimental/about"
      }}>{`Experimental`}</a></p>
    <h4 {...{
      "id": "maintainers"
    }}>{`Maintainers:`}</h4>
    <p><a parentName="p" {...{
        "href": "https://github.com/vikkipaterson"
      }}>{`Vikki Paterson`}</a></p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "47.418397626112764%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAAB00lEQVQoz1VRS08aYRTl11S7ZoAtiXtWPDT9EfyDpl0QCgzzBGYgEMR2V2OMj+qmRTBETUicxLgzbdGisUNLfVDTRzz97s0M2sXJN9+95545536B4dkpNrfWBbax8W6LsSnw/kMbO50u2p3OA3Y6XKOez2W+mF1dW8PnwQCBpddvMDM7i2g0ilA4jGBQYtB3KByBFBLnY3j1oCQxJCmEcCSCJzNP8XZ5BYF6o4n5hWfQjRKyr/KQiypkWUaxWISqqtB1HaVSaQrTNLmueZALBeZmMhkcHjoI2LaFZDKJxcWWGCijUrFgWZYYNNDr9fDt+xhfXRfuaITxeIyBiGXbtuBVmFcul/lUFAWOw4I2UqkU6vU6crkc/42a2WwWe/sHmNz9xs31D9xNrjCZ/MRweM59P0VBOPTvLEjqiUQCjUZjGlPTNBbv9/sA7vHp/ApHJy7+/rrF5cUXXoOiqMz110KzU8F4PI5Wq8X7oT1RDBLtdnfhXl6g5wyw0b/F6egPjj+6HNfn0Un3aWRfsNlswjAM3gehVqshnU4jFothYT6F+PNdzL08Q+yFA003YQhXugcyks/n/49MgtSgnVarVe9hTM+BxeAXFk7I/WOQEVoRCf4DmIDz0TeUECgAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of an import modal in context",
        "title": "Example of an import modal in context",
        "src": "/static/9ac29efca52ba539b92ee2ff24141aca/fb070/0.5.png",
        "srcSet": ["/static/9ac29efca52ba539b92ee2ff24141aca/d6747/0.5.png 288w", "/static/9ac29efca52ba539b92ee2ff24141aca/09548/0.5.png 576w", "/static/9ac29efca52ba539b92ee2ff24141aca/fb070/0.5.png 1152w", "/static/9ac29efca52ba539b92ee2ff24141aca/fb104/0.5.png 1728w", "/static/9ac29efca52ba539b92ee2ff24141aca/902fb/0.5.png 2304w", "/static/9ac29efca52ba539b92ee2ff24141aca/6690f/0.5.png 3370w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Example of an import modal in context</Caption>
    <h2 {...{
      "id": "choose-a-file"
    }}>{`Choose a file`}</h2>
    <p>{`Wherever possible there should be a drag and drop zone. Use a file drop component and offer the option to browse locally to select a file. Note that file selection should be restricted to allowed file types.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.42391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAABvElEQVQ4y62UO4/TQBSFt6BAFDQ0RIiNwt+iBVGh0KNFoAgpTkFLS0nBQ5G2pYGSgooKBAiJZRO/Mq/Y8evDM5Z3zbLZJBIjHd/R2Ofcc+/VeI//vPbso6oqh+5+F7S8E8F2tYe7ri7vH4er1QqtNUmSuv06pGnqcK7DrqCUkh/fvxHHEUpJFouFOzsLIYR7V5blxYLGLPl5FHAcKvxQEIbhuYIWtpKNDo0xRLEgXlh3whGtEwvr6iy2cGhqEYFWqi65gZDaJZBKn5xZ2GRbCdrMjmCJtXh8XPf091e0CJxot+ytBJvy6h7VQ/k1lzw7FDx+JXj/WZEldQuEdS3dNxsFkyRxonY4aWKYRUtefMh5/i7j4xdDuZKuHUutMHoLQVvubDYjCAJ836+n7JObOfkydo7mocSPmhhEVnDNlNtMw+GQXq/HYDCg3+/T39/nxs1bXLv9iav34fLdnCv3Si7dKbn+oMRXjVC5zuF0OmU0GjGZTPA8D288ZuxNePLyiEdTOHhTcfAWHr6Gp4egU6dAe/t2vMtVJ56ioa1xWBQFWZaR5/lfyIqqBp0IeVFtvikX/6roxFM0vCb+ATj7EIfF5wNaAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of an import modal",
            "title": "Example of an import modal",
            "src": "/static/f970b7d8b1fa4a5a487e039689b4bf65/fb070/2.png",
            "srcSet": ["/static/f970b7d8b1fa4a5a487e039689b4bf65/d6747/2.png 288w", "/static/f970b7d8b1fa4a5a487e039689b4bf65/09548/2.png 576w", "/static/f970b7d8b1fa4a5a487e039689b4bf65/fb070/2.png 1152w", "/static/f970b7d8b1fa4a5a487e039689b4bf65/c3e47/2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of an import modal</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.42391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAACBklEQVQ4y62Uu47TQBSGV1QIiYYOZSHpeBFqniEgIYotKJAokHYFxWqTIC4lLdtF3BHFNpRIW/AArIAEpFU2F19n7MSO7fmY2HHWioNWQYz0+xx7fD7/Z8b2Bv95bMwOSqlUxXwd5XULYD7yi+uMInSlwzAM8TyPSRCk+d8U6PkgCEtmSkDXcfjV7ehoI4XA0edCRyHcggSu66Q6Eyikz++eSd/0GZoC07RwhUwllqLn+avXMAOmGY4MOXEUfSdh4CpGQukYM3QThiLTSKuv5ywR6JIkrc1NlYCuF2JIfbMPppdpJNFAtTjPr1kinANP61cCZ65MT2mwLpYxhm7bNE0sOcVIgSoFSj8sbnfKKANlkLY3a8v2Fd96MU8/SRofJYc/Ytxx5rA7ith+7/Dw5XdefzEX61gCynGEpYtsLb2cHA3g+Wd4fACHP2FmSkygM4y4/szgxv2v3H3RJZm7XALqp9uC44HLie63p/saWpLJWDKeBBhuyLERMrCnHPUCtvZH3Nx32TuQqZmVr029XqdSqVCr1ahWq1y9ssnl6jUu1Ttc3ILztyMu3Ik5dytm816CN0mIk2ynlxxmwHa7ze7uLq1Wi2azSbOxR6P1hEevbHY+wPZbxc47ePAmW4a8s/zVWfNbVoWY6cxvOY5joigqaRorLeYxy6O4+Lf5p98XhXiqIvAPuZYJHbSw92cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of drag and drop file import",
            "title": "Example of drag and drop file import",
            "src": "/static/77476802d4f2780878201e854abe9692/fb070/3.png",
            "srcSet": ["/static/77476802d4f2780878201e854abe9692/d6747/3.png 288w", "/static/77476802d4f2780878201e854abe9692/09548/3.png 576w", "/static/77476802d4f2780878201e854abe9692/fb070/3.png 1152w", "/static/77476802d4f2780878201e854abe9692/c3e47/3.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of drag and drop file import</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.42391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAABpElEQVQ4y62Uy07CQBSGWbgwbtwZWSjhwdxp3Il7g4khJpQncaOGxOfwCYwQSm/0QjvTaQu9/HamVqrcEyf5OIch8/U/kKGGf141/pJlmaDa70N57kdYrnJz31U9t5RwNpvB932EYST6dURRJFiZsCokhGA4+MTUsUHz3nVdsfcXz/PEZ2mabhYyFkDWbEwcBsuhsG17SUYJhU99McnWhIwxqLoBeaxC03SRpArxCBxvCtlS897bnjAIGHRdhyyPoKpKkYjSRbq858KhKQv5DsIAiqJgMBjkUnnluFz4YQx3E/KRNU3DeDwWdTEuEZBv4chUxPutwjAMxZfNR+PwB3ACRnMIAj/f8/noLhjdQcjTGIYBy7IEpmkKJpaLiU1gVrAcLlzzK5dParVaqNfraDabaDQaOec4azRxcvGO4xvg8CrG0XWKg8sUp7cpTFqI0nUJ+/0+Op0Oer0eJEmC1O2iK/Xw8KTivg+0XzK0X4G7Z+DxDfAjYUB5+/a8y1mlLiiOrUmYJAnm8zniOP7FPMlyUKlAnGTbb8rmvypU6oLiXFG/AF0aER0r+UOyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of loaded files in the import modal",
            "title": "Example of loaded files in the import modal",
            "src": "/static/beb18a6151ff12516e15a471f667da1d/fb070/4.png",
            "srcSet": ["/static/beb18a6151ff12516e15a471f667da1d/d6747/4.png 288w", "/static/beb18a6151ff12516e15a471f667da1d/09548/4.png 576w", "/static/beb18a6151ff12516e15a471f667da1d/fb070/4.png 1152w", "/static/beb18a6151ff12516e15a471f667da1d/c3e47/4.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of loaded files in the import modal</Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "import-from-a-url"
    }}>{`Import from a URL`}</h2>
    <p>{`If your product supports importing from a URL, use this method.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.42391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAABbElEQVQ4y62UvU7DMBSF+0AMiIWJIf0ZkBjowNClTSTeAlAmBgZUIRATz4YqJBigbRznz7XbHHxdubXaVE0Rlk7uteX76dyrJA3882rQoyxLIzc/RLZuBbTLHh663Loth0opFEUBKaXJSZRLqVb79ZmsdugC4zjGaDRCFEXgnOs9Q5pwJDrnjugeaS8wyzIwxkycRjE+vsb4nuqcJRqcIHGUpuluoF3UbqSBk8lEAyP8jKeIeQqp5qs27Tjo7l6HeZ6bdknklDHdum6NHFGr1p3NawHdQhewqdpAGnYVgPPEEQHT+g633VQBaziczWYGKoQwQycJoWOWaHGI3FFRAxiGITzPQ6fTQavV1mrCa5/j7OYdp/fA8Z3ESahwdKvQfJhDyD3A4XCIXq+HIAjg+z78QR+D4BpXj5/ovgKXzyW6L8DFE9B/0x0pQ4B98w78lstKLct2OFwsFjtUasGJy3w9wz/9vuDEzbol8BfIERWOZpQIaQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of importing with a URL",
            "title": "Example of importing with a URL",
            "src": "/static/b358227faa469cacbe5a1ebb1cf62c84/fb070/5.png",
            "srcSet": ["/static/b358227faa469cacbe5a1ebb1cf62c84/d6747/5.png 288w", "/static/b358227faa469cacbe5a1ebb1cf62c84/09548/5.png 576w", "/static/b358227faa469cacbe5a1ebb1cf62c84/fb070/5.png 1152w", "/static/b358227faa469cacbe5a1ebb1cf62c84/c3e47/5.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of importing with a URL</Caption>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      